import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
//import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import CompanyLogo from '../assets/img/logo_1.png';
import meta_data from '../config/meta_data';
import { getCurrentUserData } from '../utils/util';
import conditional_configs from '../config/conditional_config';
import navigate_route_config from '../config/navigate_route_config';
// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
   const navigate = useNavigate();
  // const theme = useTheme();

  // const PRIMARY_LIGHT = theme.palette.primary.light;

  // const PRIMARY_MAIN = theme.palette.primary.main;

  // const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  // const logo = <Box component="img" src="/static/logo.svg" sx={{ width: 40, height: 40, ...sx }} />

  const logo = (
    <Box sx={{ width: 120, ...sx }}>
      <img src={CompanyLogo} alt={meta_data.company}></img>
    </Box>
  );

  const onLogoClick =()=>{
    if(getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.admin){
      navigate('/customers');
    }else if(getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.expert){
      navigate(navigate_route_config.expert.my_courses);
    }else if(getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.learner){
      navigate(navigate_route_config.learner.my_courses);
    }else if(getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.parent){
      navigate(navigate_route_config.parent.my_kids);
    }else if(getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.curriculam_creator){
      navigate(navigate_route_config.admin.whiteboard_templates);
    }else {
      localStorage.clear();
    }
  }
  
  // if (disabledLink) {
  //   return <>{logo}</>;
  // }

  return (
    <Box sx={{ width: 120, ...sx }} style={{cursor: 'pointer'}}>
      <img onClick={onLogoClick} src={CompanyLogo} alt={meta_data.company}></img>
    </Box>
  );
}
