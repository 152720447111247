import { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

import { getCurrentUserData } from '../../utils/util';
import conditional_configs from '../../config/conditional_config';
import navigate_route_config from '../../config/navigate_route_config';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 55;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(10),
  background: '#F2F6F8',
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.admin) {
      //navigate('/customers');
      return;
    } else if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.expert) {
      //navigate(navigate_route_config.expert.my_courses);
      return;
    } else if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.learner) {
      //navigate(navigate_route_config.learner.my_courses);
      return;
    } else if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.parent) {
      return;
      //navigate(navigate_route_config.parent.my_kids);
    } else if (getCurrentUserData() && getCurrentUserData().role == conditional_configs.role.curriculam_creator) {
      navigate(navigate_route_config.admin.whiteboard_templates);
    } else {
      localStorage.clear();
      navigate('/');
    }
  }, []);
  return (
    <div style={{height: '100%'}}>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <RootStyle>
        <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        <MainStyle>
          <Outlet />
        </MainStyle>
      </RootStyle>
    </div>
  );
}
