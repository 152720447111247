import React from "react";
import assets from "../../config/assets";
import { Button, Typography, Container, Box } from "@mui/material";
import "./home-v3.css";
import { useState, useEffect } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Card from "@mui/material/Card";

import conditional_configs from "../../config/conditional_config";
import { useNavigate } from "react-router-dom";

import google_config from "../../config/google_config";
import navigate_route_config from "../../config/navigate_route_config";

//SERVICES
import CustomerService from "../../services/customer-service";
import {
  getCurrentUserData,
  loadScriptByURL,
  setContactFormReferralCode,
  setReferralCode,
} from "../../utils/util";
import LoginButton from "../../components/login-button/login-button";
import IntegratedTools from "../../components/Integrated-tools/Integrated-tools";
import Faq from "../../components/faq/faq";
import Testimonial from "../../components/testimonial/testimonial";
import Footer from "../../components/footer/footer";
import UserNameDetails from "../../components/user-name-details/user-name-details";
import TransformativeLearning from "../../components/transformative-learning/transformative-learning";
import ContactPopup from "../../components/contact-popup/contact-popup";

export default function Homev3() {
  // mobile view toggle function
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  // mobile view toggle function end

  // section-3 Image Rotation and card show and hide
  const [imageRotation, setImageRotation] = useState(0);
  const [visibleCardIndex, setVisibleCardIndex] = useState(0);

  const cards = [
    <Card className="meeku-world-card-hv3 mt-3">
      <Box>
        <div className="d-flex align-items-center">
          <img
            className="me-2"
            src={assets.homev3.solve_card_img}
            style={{ width: "30px" }}
          ></img>
          <p className="meeku-world-card-title-hv3 m-0">
            Solve real world problems
          </p>
        </div>
        <p className="mb-0 mt-2 meeku-world-card-text-hv3">
          Our STEM platform offers hands-on experience in tackling complex
          challenges with practical problem-solving exercises.
        </p>
      </Box>
    </Card>,
    <Card className="meeku-world-card-hv3 mt-3">
      <Box>
        <div className="d-flex align-items-center">
          <img
            className="me-2"
            src={assets.homev3.team_based_card_img}
            style={{ width: "30px" }}
          ></img>
          <p className="meeku-world-card-title-hv3 m-0">Team-based learning</p>
        </div>
        <p className="mb-0 mt-2 meeku-world-card-text-hv3">
          Meeku involves collaborative approach promotes critical thinking,
          communication, and social skills, allowing children to work in teams
          with peers to solve problems.
        </p>
      </Box>
    </Card>,
    <Card className="meeku-world-card-hv3 mt-3">
      <Box>
        <div className="d-flex align-items-center">
          <img
            className="me-2"
            src={assets.homev3.experienced_card_img}
            style={{ width: "30px" }}
          ></img>
          <p className="meeku-world-card-title-hv3 m-0">Experienced Tutors</p>
        </div>
        <p className="mb-0 mt-2 meeku-world-card-text-hv3">
          At Meeku, maintain a 1:12 ratio of coaches to students, & encourage
          the development of problem-solving and STEM skills while making
          learning fun!
        </p>
      </Box>
    </Card>,
    <Card className="meeku-world-card-hv3 mt-3">
      <Box>
        <div className="d-flex align-items-center">
          <img
            className="me-2"
            src={assets.homev3.focused_card_img}
            style={{ width: "30px" }}
          ></img>
          <p className="meeku-world-card-title-hv3 m-0">Focused sessions</p>
        </div>
        <p className="mb-0 mt-2 meeku-world-card-text-hv3">
          Meeku features carefully curated power-packed sessions are short and
          engaging, maximizing children's screen time while providing an
          immersive and enjoyable experience.
        </p>
      </Box>
    </Card>,
  ]; // Add more cards if needed

  useEffect(() => {
    setReferralCode();
    setContactFormReferralCode();
    //GOOLGE RECATCHA LOAD SCRIPT
    loadScriptByURL();
    const rotateInterval = setInterval(() => {
      setImageRotation((prevRotation) => prevRotation + 90);
    }, 3000); // Rotate every 3 seconds

    const showHideInterval = setInterval(() => {
      setVisibleCardIndex((prevIndex) => (prevIndex + 1) % cards.length);
    }, 3000); // Show/hide cards every 3 seconds

    return () => {
      clearInterval(rotateInterval);
      clearInterval(showHideInterval);
    };
  }, [cards.length]);

  const [contactDialogOpen, setContactDialogOpen] = useState(false);

  const contactOpenDialog = () => {
    setContactDialogOpen(true);
  };

  const handleCancel = () => {
    setContactDialogOpen(false);
  };

  // section-3 Image Rotation and card show and hide end

  // courses_offered section background image
  const courses_offered_section_img_1 = {
    backgroundImage: `url(${assets.homev3.courses_offered_section_img_1})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    paddingTop: "100px",
    paddingBottom: "100px",
  };

  const courses_offered_section_img_2 = {
    backgroundImage: `url(${assets.homev3.courses_offered_section_img_2})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    paddingTop: "100px",
    paddingBottom: "100px",
  };
  // courses_offered section background image end

  const maskGroup_1 = {
    backgroundImage: `url(${assets.homev3.maskGroup_1})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    paddingTop: "10px",
    paddingBottom: "100px",
  };

  const backgroundRectangleImg = {
    backgroundImage: `url(${assets.homev3.backgroundRectangleImg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    paddingTop: "10px",
    paddingBottom: "0px",
  };

  const navigate = useNavigate();

  return (
    <div className="main-container-hv3">
      {/* SECTION - 1 - DESKTOP VIEW */}
      <Box
        className="large-screen"
        sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
      >
        <div style={maskGroup_1}>
          {/* header */}
          <div className="navbar-header-hv3">
            <nav className="navbar navbar-hv3 navbar-expand-lg navbar-light bg-light">
              <div className="container">
                <div className="ms-2">
                  <img
                    src={assets.course_v2.form_meeku_logo}
                    className=""
                  ></img>
                </div>
                <div className="collapse navbar-collapse" id="navbarsExample05">
                  <ul className="navbar-nav ml-auto pl-lg-5 pl-0">
                    {/* <li className="nav-item">
                      <a
                        className="nav-link nav-link-text-hv3"
                        href={conditional_configs.tempCoursesUrl.paidCoursesUrl}
                      >
                        Courses
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link nav-link-text-hv3" href="#">
                        How it works
                      </a>
                    </li> */}

                    <li className="nav-item">
                      <a
                        className="nav-link nav-link-text-hv3"
                        href={conditional_configs.tempCoursesUrl.freeCoursesUrl}
                      >
                        Enroll for a Free Demo
                      </a>
                    </li>

                    <li className="nav-item" onClick={(e)=>contactOpenDialog()}>
                  <a className="nav-link nav-link-text-hv3">
                    Contact us
                  </a>
                </li>
                  </ul>
                </div>
                {!getCurrentUserData() ? (
                  <ul className="navbar-nav me-auto">
                    <li className="nav-item cta-btn">
                      <LoginButton className={`login-btn-text-white`} />
                    </li>
                  </ul>
                ) : (
                  <ul className="navbar-nav me-auto">
                    <li className="nav-item cta-btn">
                      <UserNameDetails></UserNameDetails>
                    </li>
                  </ul>
                )}
              </div>
            </nav>
          </div>
          <div className="section-1-container-hv3 mt-5">
            <div className="container">
              <div className="row ms-0 me-0">
                <div className="col-6">
                  <div className="">
                    <p className="desktop-section-1-title">
                      Grow, learn and set your mind free
                    </p>
                    <p className="white-desc" style={{ fontWeight: "600" }}>
                      Creating the next generation of problem solvers!
                    </p>
                    <p className="white-desc">
                      Grow your child's mind with collaborative challenges and
                      equip them with the tools they need to tackle the world of
                      tomorrow!
                    </p>

                    <div className="d-flex">
                      <div
                        className="get-started-btn-hv3 me-3"
                        onClick={() =>
                          navigate(
                            conditional_configs.tempCoursesUrl.freeCoursesUrl
                          )
                        }
                      >
                        <span>Get Started</span>
                      </div>
                      {/* <div className="watch-video-btn-hv3">
                        <span>Watch Video</span>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-6"></div>
              </div>
            </div>

            <div className="m-icon">
              <img
                src={assets.homev3.m_icon}
                className=""
                style={{ width: "60%" }}
              ></img>
            </div>
          </div>
        </div>
      </Box>

      {/* SECTION - 1 - mobile VIEW */}
      <Box
        className="mobile-screen"
        sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
      >
        {/* header */}
        <nav className="navbar navbar-hv3-mobile navbar-expand-lg navbar-light bg-light">
          <div className="container">
            <div className="container d-flex align-items-center p-0">
              <button
                className="navbar-toggler toggler-icon-hv3"
                type="button"
                onClick={toggleMenu}
              >
                {isMenuOpen ? (
                  <CloseIcon color="secondary" sx={{ fontSize: 40 }} /> // Close icon
                ) : (
                  <MenuIcon color="secondary" sx={{ fontSize: 40 }} /> // Open icon
                )}
              </button>

              <div className="ms-auto">
                <img
                  src={assets.homev3.meeku_mobile_logo}
                  className="mobile-logo"
                ></img>
              </div>

              {!getCurrentUserData() ? (
                <ul className="navbar-nav ms-auto">
                  <li className="nav-item cta-btn">
                    <LoginButton className={`login-btn-text-blue`} />
                  </li>
                </ul>
              ) : (
                <ul className="navbar-nav ms-auto">
                  <li className="nav-item cta-btn">
                    <UserNameDetails></UserNameDetails>
                  </li>
                </ul>
              )}
            </div>

            <div
              className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`}
            >
              <ul className="navbar-nav ml-auto">
                {/* <li className="nav-item">
                  <a
                    className="nav-link nav-link-text-mobile-hv3"
                    href={conditional_configs.tempCoursesUrl.paidCoursesUrl}
                  >
                    Courses
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link nav-link-text-mobile-hv3" href="/">
                    How it works
                  </a>
                </li> */}
                <li className="nav-item">
                  <a
                    className="nav-link nav-link-text-mobile-hv3"
                    href={conditional_configs.tempCoursesUrl.freeCoursesUrl}
                  >
                    Enroll for a Free Demo
                  </a>
                </li>

                <li className="nav-item" onClick={(e)=>contactOpenDialog()}>
                  <a className="nav-link nav-link-text-mobile-hv3">
                    Contact us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div className="section-1-container-hv3" style={backgroundRectangleImg}>
          {/* <img className='p-0 w-100' src={assets.homev3.backgroundRectangleImg} ></img> */}

          <div className="desktop-section-1-container-hv3-mobile">
            <p className="desktop-section-1-title-mobile">
              Grow, learn and set your mind free
            </p>
            <p className="white-desc-mobile">
              Creating the next generation of problem solvers!
            </p>
            <p className="white-desc-mobile">
              Grow your child's mind with collaborative challenges and equip
              them with the tools they need to tackle the world of tomorrow!
            </p>

            <div className="d-flex justify-content-center mt-2">
              <div
                className="get-started-btn-hv3-mobile"
                onClick={() =>
                  navigate(conditional_configs.tempCoursesUrl.freeCoursesUrl)
                }
              >
                <span>Get Started</span>
              </div>
            </div>
            {/* <div className="d-flex justify-content-center mt-3">
              <div className="watch-video-btn-hv3-mobile">
                <span>Watch Video</span>
              </div>
            </div> */}
          </div>
          <div className="mt-5">
            <img
              className="p-0 w-100"
              src={assets.homev3.maskGroupMobile_1}
            ></img>
          </div>
        </div>
      </Box>

      {/* SECTION - 2 - DESKTOP VIEW mobile VIEW*/}
      <Box className="">
        <div className="section-2-container-hv3">
          <div className="large-screen">
            <div className="large-screen groupRatingIcon-hv3">
              <img
                className=""
                src={assets.homev3.groupRatingIcon}
                style={{ width: "55%" }}
              ></img>
            </div>
          </div>

          <div className="mobile-screen">
            <div className="mobile-screen groupRatingIcon-mobile-hv3">
              <img
                className=""
                src={assets.homev3.groupRatingIconobile}
                style={{ width: "70%" }}
              ></img>
            </div>
          </div>

          <div className="section-2-hv3">
            <p className="sections-title-com-hv3 m-0">
              <strong>Is your child ready to</strong> tackle the future?{" "}
            </p>
            <div className="container">
              <div className="d-flex justify-content-start">
                <img
                  className="yellowLine-img-hv3"
                  src={assets.homev3.yellowLine}
                ></img>
              </div>
            </div>
            <div className="container mt-2">
              <div className="row ms-0 me-0">
                <div className="col-lg-4 col-12">
                  <Card className="tackle-card-hv3 mt-3">
                    <Box>
                      <div className="d-flex align-items-center">
                        <div className="me-3">
                          {/* <img className="" src={assets.homev3.percentage_75} ></img> */}
                          <p className="m-0 text-75-hv3">34%</p>
                        </div>
                        <div className="">
                          <p className="m-0 tackle-card-text-hv3">
                            Students believe their schools are not preparing
                            them for success in the job market
                          </p>
                        </div>
                      </div>
                    </Box>
                  </Card>
                </div>
                <div className="col-lg-4 col-12">
                  <Card className="tackle-card-hv3 mt-3">
                    <Box>
                      <div className="d-flex align-items-center">
                        <div className="me-3">
                          {/* <img className="" src={assets.homev3.percentage_8} ></img> */}
                          <p className="m-0 text-8-hv3">
                            8<span style={{ fontWeight: "100" }}>/10</span>
                          </p>
                        </div>
                        <div className="">
                          <p className="m-0 tackle-card-text-hv3">
                            Parents feel kids school education is still not
                            sufficient
                          </p>
                        </div>
                      </div>
                    </Box>
                  </Card>
                </div>
                <div className="col-lg-4 col-12">
                  <Card className="tackle-card-hv3 mt-3">
                    <Box>
                      <div className="d-flex align-items-center">
                        <div className="me-3">
                          {/* <img className="" src={assets.homev3.percentage_50} ></img> */}
                          <p className="m-0 text-50-hv3">50%</p>
                        </div>
                        <div className="">
                          <p className="m-0 tackle-card-text-hv3">
                            Courses online are catered to working professionals
                            only
                          </p>
                        </div>
                      </div>
                    </Box>
                  </Card>
                </div>
                <div className="">
                  {" "}
                  <img
                    className="multi-union-img-hv3"
                    src={assets.homev3.multi_union}
                  ></img>
                </div>
              </div>
            </div>

            <div className="row ms-0 me-0 mt-4">
              <div className="col-lg-2"></div>
              <div className="col-lg-8">
                <p className="With-screens-text-hv3">
                  Meeku aims to ensure that your child{" "}
                  <strong>
                    uses their screen time to develop real world skills
                  </strong>{" "}
                  that would actively help them in future. With{" "}
                  <strong>courses designed to engage and educate</strong> -
                  Meeku will ensure{" "}
                  <strong>your child is ready to take on the world</strong> with
                  curiosity, enthusiasm, and determination.
                </p>
              </div>
              <div className="col-lg-2"></div>
            </div>

            <div className="d-flex justify-content-center mt-3">
              <div
                className="register-free-course-btn-hv3"
                onClick={() =>
                  navigate(conditional_configs.tempCoursesUrl.freeCoursesUrl)
                }
              >
                <span>Enroll for a Free Demo</span>
              </div>
            </div>
          </div>
        </div>
      </Box>
      <TransformativeLearning></TransformativeLearning>

      {/* SECTION - 3 - DESKTOP VIEW mobile VIEW*/}
      {/* <Box className="">
                <div className='section-3-container-hv3'>
                    <div className="section-3-hv3">
                        <p className="sections-title-com-hv3 m-0" style={{ lineHeight: "120%" }}> Meeku.World, a transformative learning<br></br> platform to <strong>STEM</strong> ecosystem </p>
                        <div className="d-flex justify-content-center">
                            <img className="yellowLine-img-hv3-2" src={assets.homev3.yellowLine} ></img>
                        </div>
                        <div className="d-flex justify-content-end">
                            <img className="section3_star_img_hv3" src={assets.homev3.section3_star_img} ></img>
                        </div>

                        <div className="container mt-4">
                            <div className="row align-items-center" style={{ paddingBottom: "50px" }}>
                                <div className="col-lg-6 col-12">
                                    <div className="image-container-hv3">
                                        <img
                                            src={assets.homev3.meeku_rotate_img}
                                            alt="Clock Vase"
                                            style={{ transform: `rotate(${imageRotation}deg)`, width: "70%" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <div className="">
                                        {cards.map((card, index) => (
                                            <div
                                                key={index}
                                                className={`card_meeku_world_v3 ${index === visibleCardIndex ? 'visible' : ''}`}
                                            >
                                                {card}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Box> */}

      {/* SECTION - 4 - DESKTOP VIEW*/}

      <IntegratedTools></IntegratedTools>

      {/* SECTION - 5 - DESKTOP VIEW*/}

      <Box className="">
        <div className="section-5-container-hv3">
          <div className="section-5-hv3">
            <p
              className="sections-title-com-hv3 m-0"
              style={{ lineHeight: "120%" }}
            >
              <strong>Courses</strong> Offered
            </p>
          </div>
          <div className="container">
            <div className="row ms-0 me-0 mt-3 mb-5">
              <div className="col-lg-6">
                <div style={courses_offered_section_img_1} className="mb-3">
                  <div className="d-flex justify-content-center">
                    <div className="courses-offered-btn-hv3-div">
                      <div className="courses-offered-btn-hv3">
                        <span>Robotics for Kids</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div style={courses_offered_section_img_2} className="mb-3">
                  <div className="d-flex justify-content-center">
                    <div className="courses-offered-btn-hv3-div">
                      <div className="courses-offered-btn-hv3">
                        <span>Basics of Coding</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>

      {/* SECTION - 6 - DESKTOP VIEW*/}

      <Faq></Faq>

      {/* SECTION - 7 - DESKTOP  VIEW*/}

      <Testimonial></Testimonial>

      {/* SECTION - 8 -  FOOTER*/}

      <Footer></Footer>

      <ContactPopup
        open={contactDialogOpen}
        onClose={() => setContactDialogOpen(false)}
      ></ContactPopup>
    </div>
  );
}
