import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Paper, Box, Grid, TextField, Typography, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { SnackbarProvider, useSnackbar } from "notistack";
import Logo from "../../components/Logo";
import { LoadingButton } from "@mui/lab";
import { getCharacterValidationError } from "../../utils/util";
//Services
import MemberService from "../../services/member-service";
import "./reset-password.css";

export default function Forgotpassword() {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  let { token } = useParams();

  console.log("token=" + token);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(/[0-9]/, getCharacterValidationError("digit"))
      .matches(/[a-z]/, getCharacterValidationError("lowercase"))
      .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
      .matches(/\W/, getCharacterValidationError("symbol")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (id) => {
    console.log(id);
    MemberService.reset_password(id.password, token)
      .then((response) => {
        if (response && response.success === "true") {
          console.log(response.data);

          // variant could be success, error, warning, info, or default
          enqueueSnackbar("successfully", {
            variant: "success",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          if (response && response.data && response.data.role === 0) {
            navigate("/admin-login");
          } else if (response && response.data && response.data.role === 1) {
            navigate("/parent-login");
          } else if (response && response.data && response.data.role === 2) {
            navigate("/learner-login");
          } else if (response && response.data && response.data.role === 3) {
            navigate("/expert-login");
          }else{
            navigate("/");
          }
        } else if (response && response.success === "false") {
          enqueueSnackbar(response.data, {
            variant: "warning",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        } else {
          enqueueSnackbar("Something went wrong. Please contact Admin.", {
            variant: "warning",
            key: "topright",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          className="reset-password-container"
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Paper className="form-bg-c">
            <Box px={3} py={2}>
              <Typography variant="h6" align="center" margin="dense">
                <div className="d-flex justify-content-center">
                  <Logo />
                </div>
                <p className="reset-text">Enter Password here..</p>
              </Typography>
              {/* FORM */}
              <Grid container spacing={1}>
                {/* Password */}
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    fullWidth
                    margin="dense"
                    {...register("password")}
                    error={errors.password ? true : false}
                    helperText={errors.password?.message}
                  />
                </Grid>
              </Grid>

              <LoadingButton
                className="reset-btn mt-3 mb-2"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                SUBMIT
              </LoadingButton>
            </Box>
          </Paper>
        </Grid>
      </form>
    </div>
  );
}
