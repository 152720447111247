import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import './course-detail-header.css';

//MUI ICONS
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

//COMPONENTS
import UserNameDetails from '../user-name-details/user-name-details';

//UTILS
import { getCurrentUserData } from '../../utils/util';

//CONFIGS
import assets from '../../config/assets';
import conditional_configs from '../../config/conditional_config';
import meta_data from '../../config/meta_data';
import LoginButton from "../login-button/login-button";

function CourseDetailHeader(props) {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [headerMenuList, setHeaderMenuList] = useState(conditional_configs.headerMenuList.filter(e=>e.active == true));
    const [activeMenu, setActiveMenu] = useState(props?.courseDetail?.courseinfo[0]?.isFree == true ? 3 : 1);

    //#region EVENTS
    const navigateTo = (page) => {
        if (page) {
            navigate(page)
        }
    }

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const RegisterFreeCourse = () => {
        window.scrollTo({ behavior: 'smooth', top: props.referPage.current.offsetTop })
    }

    const getCourseInfo = () => {
        return JSON.parse(localStorage.getItem('course-info'));
    }

    const getActiveMenu = () => {
        const courseInfo = JSON.parse(localStorage.getItem('course-info'));
        if (courseInfo && courseInfo.isFree == true) {
            return 3;
        } else {
            return 1;
        }
    }

    //#endregion

    //#region HTML DESIGN
    return (
        <div>
            {/* LARGE SCREENS */}
            <nav className="large-screen-course-header navbar navbar-cv2-course-header navbar-expand-md navbar-light bg-light">
                <div className="container p-0">
                    <div className="navbar-brand">
                        <img className="meeku_mobile_logo_cv2 me-3" src={assets.homev3.meeku_mobile_logo} onClick={() => navigateTo('/home')} ></img>
                    </div>
                    <div className="collapse navbar-collapse" id="navbarsExample05">
                        <ul className="navbar-nav ml-auto pl-lg-5 pl-0">
                            {headerMenuList?.map((obj, idx) => {
                                return (
                                    <li key={`course-header-key-` + idx} className={getActiveMenu() == obj.id ? `nav-item nav-item-active` : `nav-item`}>
                                        {getCourseInfo()?.isFree && obj.id == 3 ?
                                            <a className="nav-link nav-link-text-cv2" onClick={(e) => RegisterFreeCourse()}>{obj.value}</a>
                                            :
                                            <a className="nav-link nav-link-text-cv2" href={obj.routeTo}>{obj.value}</a>}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    {/* {getCurrentUserData() ?
                        <ul className="navbar-nav me-auto">
                            <li className="nav-item cta-btn">
                                <UserNameDetails></UserNameDetails>
                            </li>
                        </ul> :
                        <ul className="navbar-nav me-auto">
                            <li className="nav-item cta-btn">
                                <LoginButton className={'login-btn-text-blue'} />
                            </li>
                        </ul>
                    } */}
                </div>
            </nav>
            {/* MOBILE SCREENS */}
            <nav className="mobile-screen-course-header navbar navbar-cv2-mobile navbar-expand-lg navbar-light bg-light">
                <div className="container">
                    <div className="container d-flex align-items-center p-0">
                        <button
                            className="navbar-toggler toggler-icon-cv2"
                            type="button"
                            onClick={toggleMenu}
                        >
                            {isMenuOpen ? (
                                <CloseIcon color="secondary" sx={{ fontSize: 40 }} /> // Close icon
                            ) : (
                                <MenuIcon color="secondary" sx={{ fontSize: 40 }} /> // Open icon
                            )}
                        </button>
                        <div className="ms-auto">
                            <img src={assets.homev3.meeku_mobile_logo} className='mobile-logo cursor-pointer' onClick={() => navigateTo('/home')}></img>
                        </div>
                        {/* {getCurrentUserData() ?
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item cta-btn">
                                    <UserNameDetails></UserNameDetails>
                                </li>
                            </ul> : <ul className="navbar-nav ms-auto">
                                <li className="nav-item cta-btn">
                                    <LoginButton className={'login-btn-text-blue'} />
                                </li>
                            </ul>} */}
                    </div>

                    <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}>
                        <ul className="navbar-nav ml-auto">
                            {headerMenuList?.map((obj, idx) => {
                                return (
                                    <li key={`course-header-key-mobile-` + idx} className={getActiveMenu() == obj.id ? `nav-item nav-item-active` : `nav-item`}>
                                        {getActiveMenu()?.isFree && obj.id == 3 ?
                                            <a className="nav-link nav-link-text-cv2" onClick={(e) => RegisterFreeCourse()}>{obj.value}</a>
                                            :
                                            <a className="nav-link nav-link-text-cv2" href={obj.routeTo}>{obj.value}</a>}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
    //#endregion
}

export default CourseDetailHeader;
