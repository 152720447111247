import React, { useState, useEffect,useRef } from "react";
import "./my-schedule.css";
import Box from "@mui/material/Box";
import { Button, Container, Grid, Avatar } from "@mui/material";
import Card from "@mui/material/Card";
import Sampleimg from "../../../assets/img/sample_img.png";
import moment from 'moment-timezone';
import io from "socket.io-client";

import { LinearProgress } from "@mui/material";
import account from "../../../_mock/account";

//COMPONENTS
import NoRecordFound from "../../../components/no-record-found/no-record-found";

import { getCurrentUserData, getTimeZoneShortForm} from '../../../utils/util';

//SERVICE
import LearnerAPIService from '../../../services/learner-service';

//CONFIGS
import conditional_configs from '../../../config/conditional_config';
import assets from "../../../config/assets";
import error_messages from "../../../config/error_messages";
import url_configs from '../../../config/url';

let socket_conection;

export default function LearnerSchedule() {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const [Schedule_data, setSchedule_data] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState(1);

  const user_data=JSON.parse(localStorage.getItem("user"));
  console.log(user_data.id);

//GET TODAYS SESSION WHILE PAGE LOAD

  useEffect(() => {
    handleScheduleClick(conditional_configs.scheduleList[1]);
    //checkIsStartedSessions();
  }, []);


const checkIsStartedSessions =()=>{
  socket_conection = io(url_configs.breakout_room_server);
    socket_conection.on("is-started-session", (obj) => {
      console.log('is-started-session-my-schedule', obj);
      if (obj && obj.success == true) {
        var currentScheduleData = JSON.parse(JSON.stringify(Schedule_data));
        console.log('l schedules', currentScheduleData);
        if(obj.data){
          currentScheduleData.forEach(element => {
            if(element.coursestreamdetailid == obj.data.selectedSession ){
              element.isSessionStarted = true;
            }else{
              element.isSessionStarted = false;
            }
          });
          setTimeout(() => {
            setSchedule_data(currentScheduleData);
          }, 200);
        }
      } else if (obj && obj.success == false) {
        
      } else {
        return;
      }
    });
}

//GET TODAYS SCHEDULE
const todaysSchedule=()=>{
  LearnerAPIService.GetTodaySchedule(getCurrentUserData().id).then(response => {      
    setSchedule_data(response.data);
    console.log(response.data)
  })
  .catch(e => {
    console.log(e);
  });
}

//GET TOMMOROWS SCHEDULE
const tommorowsSchedule=()=>
{
  LearnerAPIService.GetTommorowsSchedule(getCurrentUserData().id).then(response => {      
    setSchedule_data(response.data);
    console.log(response.data)
    console.log(Schedule_data);
  })
  .catch(e => {
    console.log(e);
  });
}

//GET THIS WEEK SCHEDULE
const thisWeekSchedule=()=>
{
  LearnerAPIService.GetThisWeekSchedule(getCurrentUserData().id).then(response => {      
    setSchedule_data(response.data);
    console.log(response.data)
    console.log(Schedule_data);
  })
  .catch(e => {
    console.log(e);
  });
}

//GET THIS MONTH SCHEDULE
const thisMonth=()=>
{
  LearnerAPIService.GetThisMonthSchedule(getCurrentUserData().id).then(response => {      
    setSchedule_data(response.data);
    console.log(response.data)
  })
  .catch(e => {
    console.log(e);
  });
}

//GET ALL SCHEDULE
const getAllScheduledClasses = () => {
  LearnerAPIService.getAllScheduledClasses(getCurrentUserData().id).then(response => {
    setSchedule_data(response.data);
    console.log(response.data)
  })
    .catch(e => {
      console.log(e);
    });
}

const convertTime = (time) => {
  if (time) {
    let convertedTime = moment(time, 'HH:mm').tz(timeZone).format('hh:mm a');
    return convertedTime + " " + getTimeZoneShortForm();
  }
}

const handleScheduleClick=(obj)=>{
  if(obj){
    setSelectedSchedule(obj.id);
    if(obj.id == 1){
      todaysSchedule();
    }else if(obj.id == 2){
      tommorowsSchedule();
    }else if(obj.id == 3){
      thisWeekSchedule();
    }else if(obj.id == 4){
      thisMonth();
    }else{
      getAllScheduledClasses();
    }
  }
}

  return (
    <div className="pt-lg-4 pt-md-4 pt-4 ps-lg-5 ps-2 pe-lg-5 pe-2">
      <Container maxWidth="md">
        <div className="ms-lg-5 ms-md-5 ms-0">
          <Grid Container justifyContent="left">
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <span className="lan_schedule_title_text" alignItems="center">
                Schedule
              </span>
            </Grid>
          </Grid>
          <Grid container>
          {conditional_configs.scheduleList.map((obj, idx) => {
              return (
                <div className="my_schedule_button_div">
              <Button onClick={() => handleScheduleClick(obj)}
                variant="outlined"
                className={selectedSchedule == obj.id ? `Schedule_btns schedule_btns_active mb-2 me-1 me-lg-0`: `Schedule_btns schedule_btns_activemb-2 me-1 me-lg-0`}

              >
                {obj?.value}
              </Button>
            </div>
              )
            })}
          
            {/* <div className="my_schedule_button_div">
              <Button onClick={() => todaysSchedule()}
                variant="outlined"
                className="Schedule_btns mb-2 me-1 me-lg-0"
              >
                TODAY
              </Button>
            </div>
            <div className="my_schedule_button_div">
              <Button onClick={() => tommorowsSchedule()}
                variant="outlined"
                className="Schedule_btns mb-2 me-1 me-lg-0"
              >
                TOMMOROW
              </Button>
            </div>
            <div className="my_schedule_button_div">
              <Button onClick={() => thisWeekSchedule()}
                variant="outlined"
                className="Schedule_btns mb-2 me-1 me-lg-0"
              >
                THIS WEEK
              </Button>
            </div>
            <div className="my_schedule_button_div">
              <Button onClick={() => thisMonth()}
                variant="outlined"
                className="Schedule_btns mb-2 me-1 me-lg-0"
              >
                THIS MONTH
              </Button>
            </div> */}
          </Grid>

          {/* <span className="my_schedule_title ">Friday,Aug 9,2022</span> */}
        </div>
      </Container>
      <Container>
        {Schedule_data.length > 0 ? Schedule_data.map((schedule_datas) => {
          return (
            <Grid container justifyContent="center">
              {/* CARD SECTION 1 */}
              <Card className="schedule_card mt-3">
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={5} md={5} lg={5}>
                    <Box className="my_schedule_text_section">
                      <p className="my_schedule_time_text m-0">
                        {/* {schedule_datas.time}  */}
                        {moment(schedule_datas?.startdate).format("dddd")}, {moment(schedule_datas?.startdate).format('ll')} {convertTime(schedule_datas?.from)} - {convertTime(schedule_datas?.to)} {schedule_datas.session_name}
                      </p>
                      <p className="my_schedule_title">
                        {schedule_datas.schedule_title}
                      </p>
                      {/*<p><LinearProgress className="my_schedule_progress_bar" variant="determinate" value={80}  /> </p> */}
                      <p>
                        <LinearProgress
                          className="my_schedule_linear"
                          variant="determinate"
                          value={80}
                          color="success"
                          sx={{
                            height: "10px",
                            borderRadius: 2,
                            bgcolor: "#fffff",
                          }}
                        />{" "}
                      </p>
                      <span className="my_schedule_text_section">
                        <strong>
                        {schedule_datas.completed_sessions? (<>{schedule_datas.completed_sessions}</>):( 0 )}/
                          {schedule_datas.total_sessions} sessions
                        </strong>{" "}
                        Complete
                      </span>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <div className="d-flex align-items-center justify-content-center schedule_img">
                        <Avatar src={account.photoURL} alt="photoURL" className="ms-2" />
                        <div>
                          <span className="ms-2">{getCurrentUserData().name}</span>
                        </div>
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={3}
                    lg={3}
                    className="my_schedule_btn_group mt-lg-4 mt-4 mt-sm-4"
                  >
                    <Grid container justifyContent="center" alignItems="center">
                      
                     {/* {schedule_datas?.isSessionStarted ? <Button variant="outlined" className="start_btn ">
                        Join Session
                      </Button>:""} */}
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          )
        }): <Grid container justifyContent="center" className="mt-3">
        <NoRecordFound noRecordFoundImage={assets.noRecordFound.expertCourses} noRecordFoundText={error_messages.expertNoCoursesFound}></NoRecordFound>
      </Grid>}
      </Container>
      {/* CARD SECTION END*/}
    </div>
  );
}
