import roboBuilder from '../assets/img/games/robo_builder.jpg';
import roboShaper from '../assets/img/games/robo_shaper.png';
import drillGame from '../assets/img/games/drill_game.png';

//LIVE
const games = {
    gameList: [
        {
        id: 1,
        name: 'Robo Finder',
        route: 'https://live.robofinder.meeku.world/robo-finder?token=',
        routeName: 'robo-finder',
        port: '3010',
        server: 'https://live.robofinder.meeku.world/',
        domainName: 'robo-finder',
        img: roboBuilder,
        is_selected: false
    },
        {
        id: 2,
        name: 'The Drill',
        route: 'https://thedrill.meeku.world/drill-game/?token=',
        routeName: 'drill-game',
        port: '3011',
        server: 'https://thedrill.meeku.world/',
        domainName: 'thedrill',
        img: drillGame,
        is_selected: false
    },
    {
        id: 3,
        name: 'The Farming',
        route: 'https://game.thefarming.meeku.world/drill-game2/?token=',
        routeName: 'drill-game2',
        port: '3012',
        server: 'https://game.thefarming.meeku.world/',
        domainName: 'game.thefarming',
        img: drillGame,
        is_selected: false
    },
    {
        id: 4,
        name: 'The Rekindling',
        route: 'https://game.therekindling.meeku.world/drill-game3/?token=',
        routeName: 'drill-game3',
        port: '3013',
        server: 'https://game.therekindling.meeku.world/',
        domainName: 'game.therekindling',
        img: drillGame,
        is_selected: false
    },
    {
        id: 5,
        name: 'Robo Shaper',
        route: 'https://game.roboshaper.meeku.world/robo-shaper?token=',
        routeName: 'robo-shaper',
        port: '3014',
        server: 'https://game.roboshaper.meeku.world/',
        domainName: 'roboshaper',
        img: roboShaper,
        is_selected: false
    },
    {
        id: 6,
        name: 'Robo Builder',
        route: 'https://game.robobuilder.meeku.world/robo-builder?token=',
        routeName: 'robo-builder',
        port: '3015',
        server: 'https://game.robobuilder.meeku.world/',
        domainName: 'robobuilder',
        img: roboBuilder,
        is_selected: false
    },
    {
        id: 7,
        name: 'Looper',
        route: 'https://game.looper.meeku.world/looper?token=',
        routeName: 'looper',
        port: '3016',
        server: 'https://game.looper.meeku.world/',
        domainName: 'looper',
        img: drillGame,
        is_selected: false
    }
]
}

// TEST
// const games = {
//     gameList: [{
//         id: 1,
//         name: 'Drill Game 1',
//         route: 'https://test.drillgame.meeku.world/drill-game/?token=',
//         routeName: 'drill-game',
//         port: '3003',
//         server: 'https://test.drillgame.meeku.world/',
//         domainName: 'drillgame',
//         img: drillGame,
//         is_selected: false
//     },
//     {
//         id: 2,
//         name: 'Robo Builder',
//         route: 'https://test.robobuilder.meeku.world/robo-builder?token=',
//         routeName: 'robo-builder',
//         port: '3002',
//         server: 'https://test.robobuilder.meeku.world/',
//         domainName: 'robobuilder',
//         img: roboBuilder,
//         is_selected: false
//     },
//     {
//         id: 3,
//         name: 'Robo Shaper',
//         route: 'https://test.roboshaper.meeku.world/robo-shaper?token=',
//         routeName: 'robo-shaper',
//         port: '3001',
//         server: 'https://test.roboshaper.meeku.world/',
//         domainName: 'roboshaper',
//         img: roboShaper,
//         is_selected: false
//     },
//     {
//         id: 4,
//         name: 'The Farming',
//         route: 'https://test.drillgame2.meeku.world/drill-game2/?token=',
//         routeName: 'drill-game2',
//         port: '3004',
//         server: 'https://test.drillgame2.meeku.world/',
//         domainName: 'drillgame',
//         img: drillGame,
//         is_selected: false
//     },
//     {
//         id: 5,
//         name: 'The Rekindling',
//         route: 'https://test.drillgame3.meeku.world/drill-game3/?token=',
//         routeName: 'drill-game3',
//         port: '3005',
//         server: 'https://test.drillgame3.meeku.world/',
//         domainName: 'drillgame',
//         img: drillGame,
//         is_selected: false
//     },
//     {
//         id: 6,
//         name: 'The Drill',
//         route: 'https://test.drillgame1new.meeku.world/drill-game/?token=',
//         routeName: 'drill-game',
//         port: '3006',
//         server: 'https://test.drillgame1new.meeku.world/',
//         domainName: 'drillgame1new',
//         img: drillGame,
//         is_selected: false
//     },
//     {
//         id: 7,
//         name: 'Drill Game 2 New',
//         route: 'https://test.drillgame2new.meeku.world/drill-game2/?token=',
//         routeName: 'drill-game2',
//         port: '3007',
//         server: 'https://test.drillgame1new.meeku.world/',
//         domainName: 'drillgame1new',
//         img: drillGame,
//         is_selected: false
//     },
//     {
//         id: 8,
//         name: 'Looper',
//         route: 'https://test.looper.meeku.world/looper?token=',
//         routeName: 'looper',
//         port: '3008',
//         server: 'https://test.looper.meeku.world/',
//         domainName: 'looper',
//         img: drillGame,
//         is_selected: false
//     },
//     {
//         id: 9,
//         name: 'Robo Finder',
//         route: 'https://test.robofinder.meeku.world/robo-finder?token=',
//         routeName: 'robo-finder',
//         port: '3009',
//         server: 'https://test.robofinder.meeku.world/',
//         domainName: 'robo-finder',
//         img: roboBuilder,
//         is_selected: false
//     }
//     ]
// };

// LOCAL
// const games = {
//     gameList: [{
//         id: 1,
//         name: 'Drill Game 1',
//         route: 'http://localhost:3003/drill-game/?token=',
//         routeName: 'drill-game',
//         port: '3003',
//         server: 'http://localhost:3003/',
//         token: '',
//         domainName: 'drillgame',
//         img: drillGame,
//         is_selected: false
//     },
//     {
//         id: 2,
//         name: 'Robo Builder',
//         route: 'http://localhost:3002/robo-builder?token=',
//         routeName: 'robo-builder',
//         port: '3002',
//         server: 'http://localhost:3002/',
//         token: '',
//         domainName: 'robobuilder',
//         img: roboBuilder,
//         is_selected: false
//     },
//     {
//         id: 3,
//         name: 'Robo Shaper',
//         route: 'http://localhost:3001/robo-shaper?token=',
//         routeName: 'robo-shaper',
//         port: '3001',
//         server: 'http://localhost:3001/',
//         token: '',
//         domainName: 'roboshaper',
//         img: roboShaper,
//         is_selected: false
//     },
//         {
//         id: 4,
//         name: 'Drill Game 2',
//         route: 'http://localhost:3005/drill-game3/?token=',
//         routeName: 'drill-game3',
//         port: '3005',
//         server: 'http://localhost:3005/',
//         token: '',
//         domainName: 'drillgame',
//         img: drillGame,
//         is_selected: false
//     },
//     {
//         id: 5,
//         name: 'The Drill',
//         route: 'http://localhost:3005/drill-game/?token=',
//         routeName: 'drill-game',
//         port: '3005',
//         server: 'http://localhost:3005/',
//         token: '',
//         domainName: 'drillgame',
//         img: drillGame,
//         is_selected: false
//     },
//     {
//         id: 6,
//         name: 'Looper',
//         route: 'http://localhost:3008/looper?token=',
//         routeName: 'looper',
//         port: '3008',
//         server: 'http://localhost:3008/',
//         token: '',
//         domainName: 'looper',
//         img: drillGame,
//         is_selected: false
//     },
//     {
//         id: 7,
//         name: 'Robo Finder',
//         route: 'http://localhost:3006/robo-finder?token=',
//         routeName: 'robo-finder',
//         port: '3006',
//         server: 'http://localhost:3006/',
//         token: '',
//         domainName: 'robo-finder',
//         img: drillGame,
//         is_selected: false
//     }
// ]
// };
export default games;