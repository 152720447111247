const route_config = {
  admin: {
    users: {
      main_route_name: "users",
      parents: "parents",
      learners: "learners",
      experts: "experts",
      block_requests: "block-requests",
    },
    courses: {
      main_route_name: "courses",
      create_course: "create-course",
      course_info: "course-information",
      course_content: "course-content",
      course_pod: "course-pod",
      course_detail: "course-detail",
    },
    games: "games",
    quiz: "quiz",
    whiteboard_templates: "whiteboard-templates",
    customers: "customers",
    pods: "pods",
    imageLibrary: "image-library",
    share_licenses_details: "shared-licenses",
    sessions: "sessions"
  },
  parent: {
    main_route_name: "parent",
    my_kids: "my-kids",
    my_courses: "my-courses",
    my_profile: "my-profile",
    payments: "payments",
    all_courses: "all-courses",
    all_courses_detail: "course-detail",
    home: "home",
    registration: "parent-registration",
    share_licenses_details: "shared-licenses",
  },
  expert: {
    course_detail: "course-detail",
    main_route_name: "expert",
    my_courses: "my-courses",
    my_schedule: "my-schedule",
    my_profile: "my-profile",
    pods: "pods",
    curriculum: "curriculum",
    manage_groups: "manage-groups",
    group_space: "group-space",
  },
  learner: {
    course_detail: "course-detail",
    group_space: "group-space",
    main_route_name: "learner",
    my_courses: "my-courses",
    my_profile: "my-profile",
    my_schedule: "my-schedule",
    pods: "pods",
    group_space_v2: "group-space",
  },
  home: {
    privacyPolicy: "privacy-policy",
    termsAndConditions: "terms-and-conditions",
  },
  shareLicenses: "share-licenses",
};

export default route_config;
