import http from "../router/http-common";
import api_path from "../config/api";
import authHeader from "./auth/auth-header";

import { getCurrentUserData } from "../utils/util";

const changeKidPassword = async (id, newPassword) => {
  try {
    const response = await http.post(
      api_path.changeKidPassword,
      {
        id: id,
        password: newPassword,
      },
      {
        headers: authHeader(),
      }
    );
    console.log("changeKidPassword", response.data);
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

const updateProfileImage = async (id, profile_documentid) => {
  try {
    const response = await http.post(
      api_path.updateProfileImage,
      {
        id: id,
        profile_documentid: profile_documentid,
      },
      {
        headers: authHeader(),
      }
    );
    console.log("updateProfileImage", response.data);
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

const deleteDocumentId = async (id, profile_documentid) => {
  try {
    const response = await http.post(
      api_path.deleteDocumentId,
      {
        id: id,
        profile_documentid: profile_documentid,
      },
      {
        headers: authHeader(),
      }
    );
    console.log("deleteDocumentId", response.data);
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

const getMembersById = async (id) => {
  const response = await http.get(api_path.getMembersById + id, {
    headers: authHeader(),
  });
  return response.data;
};

const updateMembers = async (data) => {
  const response = await http.put(
    api_path.updateMembers + getCurrentUserData().id,
    data,
    { headers: authHeader() }
  );
  return response.data;
};

const changeNewPassword = async (data) => {
  const response = await http.put(
    api_path.changeNewPassword + getCurrentUserData().id,
    data,
    { headers: authHeader() }
  );
  return response.data;
};

const activateUser = (data) => {
  return http.post(api_path.m_activate, data).then((response) => {
    return response.data;
  });
};

const reset_password = (newpassword, token) => {
  return http
    .post(api_path.m_reset_password, {
      resettoken: token,
      password: newpassword,
    })
    .then((response) => {
      return response.data;
    });
};

//COURSE ASSIGN TO KID
const courseAssignToKid = async (data) => {
  const response = await http.post(api_path.course_assign_to_kid, data, {
    headers: authHeader(),
  });
  return response.data;
};

//LEARNERS -> GROUP SPACE -> TASK LIST
const GetTaskList = async (statusid, streamid) => {
  const response = await http.get(
    api_path.m_task_list + "?statusid=" + statusid + "&streamid=" + streamid,
    { headers: authHeader() }
  );
  return response.data;
};

//LEARNERS -> GROUP SPACE -> CREATE TASK -> SELECTING GROUP MEMBERS
const getMemberCourseGroupByStreamId = async (streamid) => {
  const response = await http.get(
    api_path.getMemberCourseGroupByStreamId + streamid,
    { headers: authHeader() }
  );
  return response.data;
};

//EXPERTS -> MANAGE GROUPS
const getMemberCourseGroupByStreamIdNew = async (streamid) => {
  const response = await http.get(
    api_path.getMemberCourseGroupByStreamIdNew + streamid,
    { headers: authHeader() }
  );
  return response.data;
};

//LEARNERS -> GROUP SPACE -> CREATE TASK
const createTask = async (data) => {
  const response = await http.post(api_path.createTask, data, {
    headers: authHeader(),
  });
  return response.data;
};

//LEARNERS -> GROUP SPACE -> CHANGE TASK STATUS
const changeStatus = async (data, taskid) => {
  const response = await http.put(api_path.changeStatus + taskid, data, {
    headers: authHeader(),
  });
  return response.data;
};

//LEARNERS -> GROUP SPACE -> DELETE TASK
const deleteTask = async (id) => {
  const response = await http.delete(api_path.deleteTask + id, {
    headers: authHeader(),
  });
  return response.data;
};

//LEARNERS -> CHAT -> GET GROUP
const getMemberCourseGroupByStreamAndLearnerId = async (
  streamid,
  learnerid
) => {
  const response = await http.get(
    api_path.getMemberCourseGroupByStreamAndLearnerId +
      "?streamid=" +
      streamid +
      "&learnerid=" +
      learnerid,
    { headers: authHeader() }
  );
  return response.data;
};

//EXPERT CREATE COURSE GROUP
const createCourseGroup = async (data) => {
  const response = await http.post(api_path.createCourseGroup, data, {
    headers: authHeader(),
  });
  return response.data;
};

//EXPERT Edit COURSE GROUP Name
const editGroupName = async (data) => {
  const response = await http.post(api_path.editGroupName, data, {
    headers: authHeader(),
  });
  return response.data;
};

//EXPERT CREATE MEMBER COURSE GROUP
const createMemberCourseGroup = async (data) => {
  const response = await http.post(api_path.createMemberCourseGroup, data, {
    headers: authHeader(),
  });
  return response.data;
};

const getMemberOnlyCourseGroupByStreamId = async (data) => {
  const response = await http.get(
    api_path.getMemberOnlyCourseGroupByStreamId + data,
    { headers: authHeader() }
  );
  return response.data;
};

//EXPERT UPDATE MEMBER COURSE GROUP
const updateMemberGroup = async (data) => {
  const response = await http.post(api_path.updateMemberGroup, data, {
    headers: authHeader(),
  });
  return response.data;
};

//EXPERT DELETE MEMBER COURSE GROUP
const deleteMemberCourseGroup = async (data) => {
  const response = await http.delete(api_path.deleteMemberCourseGroup + data, {
    headers: authHeader(),
  });
  return response.data;
};

//EXPERT DELETE COURSE GROUP
const deleteCourseGroup = async (data) => {
  const response = await http.delete(api_path.deleteCourseGroup + data, {
    headers: authHeader(),
  });
  return response.data;
};

//WHITEBOARD TABS -> GET ALL WHITEBOARDS BY USERID AND STREAMID
const getAllActiveMemberStreamBoard = async (userId, streamId) => {
  const response = await http.get(
    api_path.getAllActiveMemberStreamBoard + userId + "/" + streamId,
    { headers: authHeader() }
  );
  return response.data;
};

//WHITEBOARD TABS -> GET ALL WHITEBOARDS BY GROUP ID
const getAllActiveByGroupId = async (groupId) => {
  const response = await http.get(
    api_path.getAllActiveByGroupId + groupId,
    { headers: authHeader() }
  );
  return response.data;
};

//ADD MEMBER STREAM BOARD(ADDING TABS)
const addMemberStreamBoard = async (data) => {
  const response = await http.post(api_path.addMemberStreamBoard, data, {
    headers: authHeader(),
  });
  return response.data;
};

//REMOVE MEMBER STREAM BOARD(STATUS TO 0)
const softDeleteMemberStreamBoard = async (id, data) => {
  const response = await http.put(
    api_path.softDeleteMemberStreamBoard + id,
    data,
    { headers: authHeader() }
  );
  return response.data;
};

const contactUs = async (data) => {
  const response = await http.post("/contact-us", data, {
    headers: authHeader(),
  });
  return response.data;
};

const updateKidDetails = async (id, first_name, last_name) => {
  try {
    const response = await http.post(
      api_path.updateKidDetails,
      {
        id: id,
        first_name: first_name,
        last_name: last_name,
      },
      {
        headers: authHeader(),
      }
    );
    console.log("updateKidDetails", response.data);
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

const MemberService = {
  getMembersById,
  updateMembers,
  changeNewPassword,
  activateUser,
  reset_password,
  GetTaskList,
  getMemberCourseGroupByStreamId,
  getMemberCourseGroupByStreamIdNew,
  createTask,
  changeStatus,
  deleteTask,
  getMemberCourseGroupByStreamAndLearnerId,
  courseAssignToKid,
  createCourseGroup,
  createMemberCourseGroup,
  getMemberOnlyCourseGroupByStreamId,
  updateMemberGroup,
  deleteMemberCourseGroup,
  deleteCourseGroup,
  getAllActiveMemberStreamBoard,
  getAllActiveByGroupId,
  addMemberStreamBoard,
  softDeleteMemberStreamBoard,
  editGroupName,
  contactUs,
  changeKidPassword,
  deleteDocumentId,
  updateProfileImage,
  updateKidDetails,
};
export default MemberService;
