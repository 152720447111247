import http from "../router/http-common";
import api_path from "../config/api";
import authHeader from "./auth/auth-header";

const createStripePayment = async (data) => {
    const response = await http.post('/create-payment-intent',data, { headers: authHeader() });
    return response.data;
  };
const PaymentService = {
    createStripePayment
};
export default PaymentService;